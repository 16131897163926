import {toQueryString} from "@/cms-services/helpers";
import {IBaseConfig} from "@/ioc/types";
import axios from "axios";
import {ListDataSource} from "./ListDataSource";

export class CommodityListDataSource extends ListDataSource {
  constructor(config: IBaseConfig = {}) {
    super({
      className: "commodity",
      config,
    });
  }

  public async uploadCsv(event: any, folderId: number | null) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/import/csv${toQueryString({ folderId })}`,
        event.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress(e) {
            event.onProgress(e, event.file);
          },
        },
      );

      this.items = [...data, ...this.items];
    } catch (err) {
      throw err;
    }
  }

  public async updateFromCsv(event: any) {
    try {
      await axios.post(
          `${this.baseUrl}/update/csv`,
          event.data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "*/*",
            },
            onUploadProgress(e) {
              event.onProgress(e, event.file);
            },
          },
      );
    } catch (err) {
      throw err;
    }
  }
}


