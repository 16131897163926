
import { Vue, Component, Ref } from "vue-property-decorator";
import { CommodityListDataSource } from "@/data/List/CommodityListDataSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import {mdiDownload, mdiPlusCircleOutline, mdiUpload} from "@mdi/js";
import DataList from "@/components/common/data/list.vue";
import DateTimeEditor from "@/components/date-time-range.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Filter } from "@/cms-services/filter";
@Component({
  components: { DateTimeEditor, FileUploaderDialog },
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  uploaderDialogVisible: boolean = false;
  updateCsvDialogVisible = false;
  dataSource: CommodityListDataSource = new CommodityListDataSource({
    pageIndex: 1,
    pageSize: 15,
  });
  icons: any = {
    download: mdiDownload,
    circlePlus: mdiPlusCircleOutline,
    upload: mdiUpload,
  };

  productSeriesDataSource: ListDataSource = new ListDataSource({
    className: "ProductSeries",
    config: {
      pageSize: 100,
    },
  });

  brandDataSource: ListDataSource = new ListDataSource({
    className: "brand",
    config: {
      pageSize: 100,
    },
  });

  folderConfig: any = {
    folderId: null,
    className: "commodityFolder",
    rootFolder: "mainFolder",
  };
  $message: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Товары",
        disabled: true,
      },
    ];
  }
  filter = {
    brandId: 0,
    productSeriesId: 0,
    searchText: "",
  };

  async updateFilter() {
    this.dataSource.config.filter = JSON.stringify(
      Object.entries(this.filter)
        .filter(([, v]) => !!v)
        .map(([k, v]) => new Filter(k, v as string))
    );
    await this.dataSource.get();
  }

  async mounted() {
    await Promise.all([
      this.productSeriesDataSource.get(),
      this.brandDataSource.get(),
    ]);
  }

  loading: boolean = false;
  async uploadFile(event: any) {
    try {
      this.loading = true;
      await this.dataSource.uploadCsv(event, +this.folderConfig.folderId);
      await this.dataList.refreshData();
      this.$message("Успешно загружено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    } finally {
      this.uploaderDialogVisible = false;
      this.loading = false;
    }
  }

  async updateFromFile(event: any) {
    try {
      this.loading = true;
      await this.dataSource.updateFromCsv(event);
      await this.dataList.refreshData();
      this.$message("Успешно загружено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    } finally {
      this.uploaderDialogVisible = false;
      this.loading = false;
    }
  }
}
